import { FC, ReactNode, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from '../header';
import Sidebar from '../side-bar';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'src/store/configure-store';
import { useSelector } from 'react-redux';
import { getUserAccounts } from 'src/modules/Settings/OrganizationManagement/services/organizationManagement.service';
import {
  getAccessKeys,
  getPlaneCollection,
  getPolicyMasterData,
  getRoles,
  getUsers
} from 'src/modules/common/services/common.services';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const {
    policyMasterData,
    iamUsers,
    iamRoles,
    iamUsersLastEvaluatedKey,
    iamRolesLastEvaluatedKey,
    accessKeys,
    accessKeysLastEvaluatedKey,
    planeCollection
  } = useSelector((state: RootState) => state.common);
  const { userAccounts } = useSelector(
    (state: RootState) => state.organizationManagement
  );

  //preloading data for performance improvement
  useEffect(() => {
    if (!(policyMasterData && Object.entries(policyMasterData)?.length))
      dispatch(getPolicyMasterData());
    if (!(userAccounts && userAccounts?.length)) dispatch(getUserAccounts());
    const data = {
      limit: 1000
    };
    if (!(iamUsers && iamUsers?.length)) dispatch(getUsers(data));
    if (!(iamRoles && iamRoles?.length)) dispatch(getRoles(data));
    if (!(accessKeys && accessKeys?.length)) dispatch(getAccessKeys(data));
    if (!(planeCollection && planeCollection?.length))
      dispatch(getPlaneCollection());
  }, []);

  //handling paginated response of iam users
  useEffect(() => {
    if (iamUsersLastEvaluatedKey) {
      const data = {
        limit: 1000,
        last_evaluated_key: iamUsersLastEvaluatedKey
      };
      dispatch(getUsers(data));
    }
  }, [iamUsersLastEvaluatedKey]);

  //handling paginated response of iam roles
  useEffect(() => {
    if (iamRolesLastEvaluatedKey) {
      const data = {
        limit: 1000,
        last_evaluated_key: iamRolesLastEvaluatedKey
      };
      dispatch(getRoles(data));
    }
  }, [iamRolesLastEvaluatedKey]);

  //handling paginated response of iam roles
  useEffect(() => {
    if (accessKeysLastEvaluatedKey) {
      const data = {
        limit: 1000,
        last_evaluated_key: accessKeysLastEvaluatedKey
      };
      dispatch(getAccessKeys(data));
    }
  }, [accessKeysLastEvaluatedKey]);

  return (
    <>
      <Box
        className={
          theme.palette.mode === 'dark'
            ? 'layoutbox MuiPageTitlewrapperDark '
            : 'layoutbox MuiPageTitlewrapperLight '
        }
      >
        <Header />
        <Sidebar />
        <Box
          className="layout"
          sx={{
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            }
          }}
        >
          <Box>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
